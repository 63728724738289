import { useActionBarAnchorPoint } from "@/hooks/use-actionbar-anchor-point";
import { PointCloudObject } from "@/object-cache";
import { selectActiveAnalysis } from "@/store/point-cloud-analysis-tool-selector";
import {
  PointCloudAnalysis,
  ReferencePlaneType,
  setActiveAnalysis,
  setAnalysisElevation,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { GridPlane, Z_TO_Y_UP_QUAT } from "@faro-lotv/app-component-toolbox";
import {
  ColormapPoints,
  getLotvMath,
  PolygonSelectionResult,
  selectPointsByPolygon,
} from "@faro-lotv/lotv";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Box3, Plane, Quaternion, Vector2, Vector3 } from "three";
import { AnalysisActionBar } from "./analysis-action-bar";
import { colormapPresets } from "./colormap-options-panel";

type ColorMapAnalysisRendererProps = {
  /** Point cloud object used by analysis */
  pointCloud: PointCloudObject;

  /** The point cloud analysis to be rendered */
  analysis: PointCloudAnalysis;
};

/** @returns The renderer of a colormap analysis */
export function ColormapAnalysisRenderer({
  pointCloud,
  analysis,
}: ColorMapAnalysisRendererProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const polygon = useMemo(
    () => analysis.polygonSelection.map((p) => new Vector3().fromArray(p)),
    [analysis.polygonSelection],
  );

  const [selection, setSelection] = useState<PolygonSelectionResult>();
  // compute the selected planar points, ony when the polygon or point cloud changes
  useEffect(() => {
    const computePoints = async (): Promise<void> => {
      const selection = await selectPointsByPolygon(pointCloud, {
        polygon,
        planeThreshold: 0.1,
        maxNumberOfPoints: 1_000_000,
        minPointDensity: 3,
      });
      setSelection(selection);
    };
    computePoints().catch(console.error);
  }, [pointCloud, polygon]);

  // The best fit plane in world CS for the selection
  const [fitPlane, setFitPlane] = useState<{
    normal: Vector3;
    point: Vector3;
  }>();
  useEffect(() => {
    if (!selection) return;
    const computeFitPlane = async (): Promise<void> => {
      const lotvMath = await getLotvMath();
      const fitResult = lotvMath.fitPlane(selection.points);
      if (fitResult) {
        setFitPlane({
          normal: new Vector3(
            fitResult.normal.x,
            fitResult.normal.y,
            fitResult.normal.z,
          ),
          // translate point to world CS
          point: new Vector3(
            fitResult.point.x + selection.origin.x,
            fitResult.point.y + selection.origin.y,
            fitResult.point.z + selection.origin.z,
          ),
        });
      } else {
        setFitPlane(undefined);
      }
    };
    computeFitPlane().catch(console.error);
  }, [selection]);

  const [colormapAnalysis, setColormapAnalysis] = useState<ColormapPoints>();

  // Create colormap analysis object with some tempory values.
  // No dependency on analysis parameters to avoid recreating the 3js object whenever
  // the analysis parameters are modified.
  useEffect(() => {
    if (!selection) {
      setColormapAnalysis(undefined);
      return;
    }

    const colormapPoints = new ColormapPoints(
      selection.points,
      selection.origin,
      new Plane(),
      colormapPresets.rainbow,
      -1.0,
      1.0,
    );
    colormapPoints.pointSize = 0.04;
    setColormapAnalysis(colormapPoints);

    return () => {
      colormapPoints.dispose();
    };
  }, [selection]);

  const [planePosition, setPlanePosition] = useState<Vector3>();
  const [planeQuaternion, setPlaneQuaternion] = useState<Quaternion>();
  const extents = useMemo(() => {
    const box = new Box3().setFromPoints(polygon);
    const size = box.getSize(new Vector3()).length();
    return new Vector2(size, size);
  }, [polygon]);

  // Update colormap with the correct analysis parameters
  useEffect(() => {
    if (!fitPlane || !colormapAnalysis) return;

    const planePoint = fitPlane.point.clone();
    const planeNormal = fitPlane.normal.clone();

    if (analysis.referencePlaneType === ReferencePlaneType.level) {
      planeNormal.set(0.0, 1.0, 0.0);
      if (analysis.elevation) {
        planePoint.setY(analysis.elevation);
      } else {
        // update the store when the elevation is undefined
        // using the fitted plane center as the initial elevation
        dispatch(
          setAnalysisElevation({
            analysisId: analysis.id,
            elevation: planePoint.y,
          }),
        );
      }
    }

    const plane = new Plane();
    plane.setFromNormalAndCoplanarPoint(planeNormal, planePoint);
    colormapAnalysis.referencePlane = plane;
    colormapAnalysis.minColorDeviation = -analysis.tolerance;
    colormapAnalysis.maxColorDeviation = analysis.tolerance;

    setPlanePosition(planePoint);
    const quaternion = new Quaternion().setFromUnitVectors(
      new Vector3(0, 1, 0),
      planeNormal,
    );
    quaternion.multiply(Z_TO_Y_UP_QUAT);
    setPlaneQuaternion(quaternion);
  }, [
    analysis.elevation,
    analysis.id,
    analysis.referencePlaneType,
    analysis.tolerance,
    colormapAnalysis,
    dispatch,
    fitPlane,
  ]);

  // Anchor point for the action bar
  const anchorPoint = useActionBarAnchorPoint(polygon);
  const activeAnalysis = useAppSelector(selectActiveAnalysis);

  const onClickAnalysis = useCallback(
    (e: PointerEvent) => {
      e.stopPropagation();
      if (activeAnalysis?.id === analysis.id) {
        dispatch(setActiveAnalysis(undefined));
      } else {
        dispatch(setActiveAnalysis(analysis));
      }
    },
    [activeAnalysis?.id, analysis, dispatch],
  );

  if (!colormapAnalysis) return null;

  return (
    <>
      <primitive object={colormapAnalysis} onClick={onClickAnalysis} />
      {activeAnalysis?.id === analysis.id && (
        <>
          <AnalysisActionBar
            anchorPoint={anchorPoint}
            colormapPoints={colormapAnalysis}
            analysis={analysis}
          />
          {analysis.showReferencePlane && (
            <group position={planePosition} quaternion={planeQuaternion}>
              <GridPlane size={extents} />
            </group>
          )}
        </>
      )}
    </>
  );
}
