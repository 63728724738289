import { AlignmentTransform } from "@/alignment-tool/store/alignment-slice";
import { TwoPointsPairsAlignmentAnchorPositions } from "@/alignment-tool/utils/compute-split-screen-alignment";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/**
 * The layout type of the split screen in 2D alignment step
 */
export enum AlignmentViewLayout {
  splitScreen = "splitScreen",
  overlay = "overlay",
}

/**
 * This defines all the steps to align sheet to a 3D model
 * IMPORTANT: The order of definition matters. It's used as index
 * and to determine what is the previous or next step.
 */
export enum SheetToCadAlignmentStep {
  setElevation = "setElevation",
  alignSheet = "alignSheet",
}

export enum SplitDirection {
  // split screen into left screen and right screen
  horizontalSplit = "horizontalSplit",

  // split screen into top screen and bottom screen
  verticalSplit = "verticalSplit",
}

/** State managing data exchange during sheet-to-cad alignment session */
type SheetToCadAlignmentToolState = {
  /** reference to sheet ID used during current alignment session */
  sheetIdToAlignWithCad?: string;

  /** Alignment step */
  step: SheetToCadAlignmentStep;

  /** CAD alignment screen layout */
  alignmentLayout: AlignmentViewLayout;

  /** CAd alignment screen split direction */
  splitDirection: SplitDirection;

  /** if true clipping box in Vertical alignment step is enabled */
  isClippingBoxEnabled: boolean;

  /** if true, extract cross section at sheet elevation for cad model tomographic view */
  isCrossSectionEnabled: boolean;

  /**
   * Elevation of sheet plane relatively to CAD origin in W.C.S., in meters
   * the value is obtained from TransformControls widget in Alignment Tool UI
   * at the end of each alignment cycle must be reset to undefined to prevent reusing it in next session of alignment
   */
  sheetElevation?: number;

  /**
   * Incremental transform of the the CAD position, in C.S. of previous CAD position, translations are in meters
   * at the end of each alignment cycle must be reset to undefined to prevent reusing it in next session of alignment
   */
  incrementalTransform?: AlignmentTransform;

  /** anchor positions defined in split screen alignment */
  alignmentAnchorPositions: TwoPointsPairsAlignmentAnchorPositions;
};

const initialState: SheetToCadAlignmentToolState = {
  step: SheetToCadAlignmentStep.setElevation,
  alignmentLayout: AlignmentViewLayout.splitScreen,
  splitDirection: SplitDirection.horizontalSplit,
  alignmentAnchorPositions: {},
  isClippingBoxEnabled: false,
  isCrossSectionEnabled: true,
};

export const sheetToCadAlignmentModeSlice = createSlice({
  initialState,
  name: "sheetToCadAlignmentMode",
  reducers: {
    setSheetForCadAlignment(state, action: PayloadAction<string>) {
      state.sheetIdToAlignWithCad = action.payload;
    },

    setSheetToCadAlignmentStep(
      state,
      action: PayloadAction<SheetToCadAlignmentStep>,
    ) {
      state.step = action.payload;
    },

    setSheetToCadAlignmentLayout(
      state,
      action: PayloadAction<AlignmentViewLayout>,
    ) {
      state.alignmentLayout = action.payload;
    },

    setSheetToCadAlignmentSplitDirection(
      state,
      action: PayloadAction<SplitDirection>,
    ) {
      state.splitDirection = action.payload;
    },

    setClippingBoxEnabled(state, action: PayloadAction<boolean>) {
      state.isClippingBoxEnabled = action.payload;
    },

    setAreaToCadTransform(
      state,
      action: PayloadAction<AlignmentTransform | undefined>,
    ) {
      state.incrementalTransform = action.payload;
    },

    setSheetElevation(state, action: PayloadAction<number | undefined>) {
      state.sheetElevation = action.payload;
    },

    setCrossSectionEnabled(state, action: PayloadAction<boolean>) {
      state.isCrossSectionEnabled = action.payload;
    },

    resetSheetToCadAlignment(state) {
      state.sheetElevation = undefined;
      state.incrementalTransform = undefined;
      state.sheetIdToAlignWithCad = undefined;
      state.alignmentAnchorPositions = {};
    },

    setReferenceElementAnchor1(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor1 = action.payload;
    },

    setReferenceElementAnchor2(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.referenceElementAnchor2 = action.payload;
    },

    setMovingElementAnchor1(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor1 = action.payload;
    },

    setMovingElementAnchor2(
      state,
      action: PayloadAction<Vector3Tuple | undefined>,
    ) {
      state.alignmentAnchorPositions.movingElementAnchor2 = action.payload;
    },
  },
});

export const {
  setSheetForCadAlignment,
  setSheetToCadAlignmentStep,
  setSheetToCadAlignmentLayout,
  setSheetToCadAlignmentSplitDirection,
  setClippingBoxEnabled,
  setAreaToCadTransform,
  setSheetElevation,
  resetSheetToCadAlignment,
  setMovingElementAnchor1,
  setMovingElementAnchor2,
  setReferenceElementAnchor1,
  setReferenceElementAnchor2,
  setCrossSectionEnabled,
} = sheetToCadAlignmentModeSlice.actions;

export const sheetToCadAlignmentModeReducer =
  sheetToCadAlignmentModeSlice.reducer;
